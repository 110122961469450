<template>
  <esmp-modal
    v-model="isShow"
    :closable="false"
    :mask-closable="false"
    :width="800"
    class="modal-edit-document"
  >
    <template #header>
      {{ isEditMode ? 'Изменить' : 'Создать' }} документ
    </template>
    <validation-observer ref="editDocumentForm" :disabled="!isShow">
      <hm-form
        class="modal-edit-document__fields"
        :fields="fields"
        v-model="localValue"
      />
    </validation-observer>
    <template #footer>
      <esmp-button @click="save" size="small">
        Сохранить
      </esmp-button>
      <esmp-button
        @click="cancel"
        size="small"
        view="outline"
      >
        Отменить
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
import isNumber from 'lodash/isNumber';
import HmForm from '@/components/hm-form/HmForm.vue';

export default {
  name: 'ModalEditDocument',
  components: { HmForm },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: undefined,
    },
    document: {
      type: Object,
      default: undefined,
    },
    documentIndex: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      localValue: this.document || {},
    };
  },
  computed: {
    isEditMode() {
      return !isNumber(this.documentIndex);
    },
  },
  watch: {
    isShow(val) {
      this.localValue = val ? this.document || {} : {};
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.editDocumentForm.validate();

      if (isValid) {
        this.$emit('update-document', this.localValue, this.documentIndex);
        this.$emit('close-modal');
      }
    },
    cancel() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss">
.modal-edit-document {
  &__fields {
    display: flex;
    flex-direction: column;
    gap: $base-gutter;
  }
}
</style>
