<template>
  <validation-provider
    :rules="`document:${rules.required}`"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <esmp-select v-model="localModel" hidden />
    <div
      :class="{
        'hm-document': true,
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="hm-form__element-label">
        {{ name }}
        <span v-if="required">*</span>
      </div>
      <div class="hm-document__add-btn" v-if="localModel.length === 1">
        <esmp-button
          view="outline"
          size="small"
          @click="addDocument(0)"
        >
          {{ isEmptyDocument(localModel[0]) ? 'Добавить документ' : 'Просмотреть документ' }}
        </esmp-button>
      </div>
      <template v-if="localModel.length > 1">
        <div
          v-for="(localDocument, index) in localModel"
          :key="index"
          class="hm-document__add-btn"
        >
          <esmp-button
            view="outline"
            size="small"
            @click="addDocument(index)"
          >
            {{ isEmptyDocument(localModel[index]) ? 'Добавить документ' : 'Просмотреть документ' }}
          </esmp-button>
          <esmp-button
            v-if="index > 0"
            size="small"
            view="function"
            icon="trash"
            @click="removeDocument(index)"
          />
        </div>
      </template>
      <esmp-button
        v-if="addMoreDocument"
        size="small"
        @click="addAnotherDocument"
      >
        Добавить еще
      </esmp-button>
    </div>
    <modal-edit-document
      v-if="fields.length"
      :document="selectedDocument"
      :document-index="selectedDocumentIndex"
      :is-show="isDocumentShowed"
      :fields="fields"
      @close-modal="closeModalEditDocument"
      @update-document="updateDocument"
    />
  </validation-provider>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import isNumber from 'lodash/isNumber';
import uid from '@/components/hm-form/mixins/uid';
import ModalEditDocument from '@/components/modals/modal-edit-document';
import mappedFields from '@/components/hm-form/helpers/mappedFields';
import getFileList from '@/components/hm-form/helpers/getFileList';

export default {
  name: 'HmDocument',
  components: { ModalEditDocument },
  mixins: [uid],
  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },
    documentId: {
      type: [String, Number],
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    addMoreDocument: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    documents: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      isDocumentShowed: false,
      fields: [],
      selectedDocument: null,
      selectedDocumentIndex: null,
      model: [{}],
    };
  },
  computed: {
    ...mapState('document', ['documentAttachments']),
    localModel: {
      get() {
        return this.model.length ? this.model : [{}];
      },
      set(value) {
        this.model = value;

        const mappedModel = this.model.map((el) => mappedFields(el, this.fields, undefined));

        const documents = mappedModel.map((list, index) => ({
          documentAttachments: this.getDocumentAttachments(index),
          checklistItems: list,
          formId: this.documentId,
        }));
        this.onInput(documents);
      },
    },
    sourceSystem() {
      return this.$route?.params?.source || 'otrs1';
    },
  },
  methods: {
    ...mapActions('document', ['setDocumentAttachments']),
    getDocumentAttachments(index) {
      const localDocuments = { ...this.documentAttachments };
      const filesForSend = this.model.map((el) => getFileList(el, this.fields));
      localDocuments[this.id] = [...filesForSend];
      this.setDocumentAttachments(localDocuments);

      return filesForSend[index].map((el, i) => ({
        attachmentName: el.name,
        attachmentId: `${this.id}-${index}-${i}`,
      }));
    },
    isEmptyDocument(document) {
      return !Object.keys(document).length;
    },
    addDocument(index) {
      if (this.localModel[index]) {
        this.selectedDocument = this.localModel[index];
      } else {
        this.selectedDocument = null;
      }
      this.selectedDocumentIndex = index;
      this.isDocumentShowed = true;
    },
    updateDocument(document, documentIndex) {
      const newLocalModel = [...this.localModel];
      if (isNumber(documentIndex)) {
        newLocalModel[documentIndex] = document;
      } else {
        newLocalModel.push(document);
      }
      this.localModel = newLocalModel;
    },
    onInput(value) {
      this.$emit('input', value);
    },
    closeModalEditDocument() {
      this.isDocumentShowed = false;
      this.selectedDocument = null;
      this.selectedDocumentIndex = null;
    },
    addAnotherDocument() {
      this.localModel = [...this.localModel, {}];
    },
    removeDocument(index) {
      this.localModel = this.localModel.splice(index, 1);
    },
    getDocumentChecklist() {
      this.$API.checklist.getDocumentCheckList(this.documentId, {
        id: this.id,
        source: this.sourceSystem,
      }).then(({ data }) => {
        this.fields = data.fields;
      });
    },
  },
  created() {
    this.getDocumentChecklist();
  },
  beforeDestroy() {
    this.setDocumentAttachments({});
  },
};
</script>
<style lang="scss">
.hm-document {
  &__add-btn {
    margin-bottom: $base-gutter;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $base-gutter / 2;
  }
}
</style>
